/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { InventoryItem } from './InventoryItem';
import {
    InventoryItemFromJSON,
    InventoryItemFromJSONTyped,
    InventoryItemToJSON,
} from './InventoryItem';

/**
 * 
 * @export
 * @interface Manufacturer
 */
export interface Manufacturer {
    /**
     * 
     * @type {string}
     * @memberof Manufacturer
     */
    manufacturer?: string;
    /**
     * 
     * @type {Array<InventoryItem>}
     * @memberof Manufacturer
     */
    items?: Array<InventoryItem>;
}

/**
 * Check if a given object implements the Manufacturer interface.
 */
export function instanceOfManufacturer(value: object): value is Manufacturer {
    return true;
}

export function ManufacturerFromJSON(json: any): Manufacturer {
    return ManufacturerFromJSONTyped(json, false);
}

export function ManufacturerFromJSONTyped(json: any, ignoreDiscriminator: boolean): Manufacturer {
    if (json == null) {
        return json;
    }
    return {
        
        'manufacturer': json['manufacturer'] == null ? undefined : json['manufacturer'],
        'items': json['items'] == null ? undefined : ((json['items'] as Array<any>).map(InventoryItemFromJSON)),
    };
}

export function ManufacturerToJSON(value?: Manufacturer | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'manufacturer': value['manufacturer'],
        'items': value['items'] == null ? undefined : ((value['items'] as Array<any>).map(InventoryItemToJSON)),
    };
}

