/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { InventoryItemDetail } from './InventoryItemDetail';
import {
    InventoryItemDetailFromJSON,
    InventoryItemDetailFromJSONTyped,
    InventoryItemDetailToJSON,
} from './InventoryItemDetail';

/**
 * 
 * @export
 * @interface InventoryItem
 */
export interface InventoryItem {
    /**
     * 
     * @type {number}
     * @memberof InventoryItem
     */
    totalRequested?: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryItem
     */
    totalOnHand?: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryItem
     */
    numberOfRequests?: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryItem
     */
    productName?: string;
    /**
     * 
     * @type {Array<InventoryItemDetail>}
     * @memberof InventoryItem
     */
    inventoryItems?: Array<InventoryItemDetail>;
}

/**
 * Check if a given object implements the InventoryItem interface.
 */
export function instanceOfInventoryItem(value: object): value is InventoryItem {
    return true;
}

export function InventoryItemFromJSON(json: any): InventoryItem {
    return InventoryItemFromJSONTyped(json, false);
}

export function InventoryItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryItem {
    if (json == null) {
        return json;
    }
    return {
        
        'totalRequested': json['totalRequested'] == null ? undefined : json['totalRequested'],
        'totalOnHand': json['totalOnHand'] == null ? undefined : json['totalOnHand'],
        'numberOfRequests': json['numberOfRequests'] == null ? undefined : json['numberOfRequests'],
        'productName': json['productName'] == null ? undefined : json['productName'],
        'inventoryItems': json['inventoryItems'] == null ? undefined : ((json['inventoryItems'] as Array<any>).map(InventoryItemDetailFromJSON)),
    };
}

export function InventoryItemToJSON(value?: InventoryItem | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'totalRequested': value['totalRequested'],
        'totalOnHand': value['totalOnHand'],
        'numberOfRequests': value['numberOfRequests'],
        'productName': value['productName'],
        'inventoryItems': value['inventoryItems'] == null ? undefined : ((value['inventoryItems'] as Array<any>).map(InventoryItemDetailToJSON)),
    };
}

