import { Helmet } from 'react-helmet-async';

const MetaTags = () => {
    const title = 'Reply AI';
    const description = 'AI-powered sales optimization software designed for distributors and manufacturers to boost revenue, streamline operations, and reduce overhead costs';
    const url = window.location.origin;

    return (
        <Helmet>
            {/* Standard metadata */}
            <title>{title}</title>
            <meta name="description" content={description} />

            {/* Open Graph / Facebook */}
            <meta property="og:type" content="website" />
            <meta property="og:url" content={url} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />

            {/* Twitter */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={url} />
            <meta property="twitter:title" content={title} />
            <meta property="twitter:description" content={description} />
        </Helmet>
    );
};

export default MetaTags; 