/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InventoryItemDetail
 */
export interface InventoryItemDetail {
    /**
     * 
     * @type {string}
     * @memberof InventoryItemDetail
     */
    fromEmail?: string;
    /**
     * 
     * @type {Date}
     * @memberof InventoryItemDetail
     */
    emailDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof InventoryItemDetail
     */
    quantityOnHand?: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryItemDetail
     */
    quantity?: number;
}

/**
 * Check if a given object implements the InventoryItemDetail interface.
 */
export function instanceOfInventoryItemDetail(value: object): value is InventoryItemDetail {
    return true;
}

export function InventoryItemDetailFromJSON(json: any): InventoryItemDetail {
    return InventoryItemDetailFromJSONTyped(json, false);
}

export function InventoryItemDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryItemDetail {
    if (json == null) {
        return json;
    }
    return {
        
        'fromEmail': json['fromEmail'] == null ? undefined : json['fromEmail'],
        'emailDate': json['emailDate'] == null ? undefined : (new Date(json['emailDate'])),
        'quantityOnHand': json['quantityOnHand'] == null ? undefined : json['quantityOnHand'],
        'quantity': json['quantity'] == null ? undefined : json['quantity'],
    };
}

export function InventoryItemDetailToJSON(value?: InventoryItemDetail | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'fromEmail': value['fromEmail'],
        'emailDate': value['emailDate'] == null ? undefined : ((value['emailDate']).toISOString()),
        'quantityOnHand': value['quantityOnHand'],
        'quantity': value['quantity'],
    };
}

