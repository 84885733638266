import { HelmetProvider } from 'react-helmet-async';
import MetaTags from './components/MetaTags';
import RequireAuth from "./layouts/RequireAuth";
import HasAuth from "./layouts/HasAuth";
import { ObserveState } from "./StateManagement/ObserveState";
import { Global } from "./Global";
import { useEffect } from "react";

function App() {
  return (
    <HelmetProvider>
      <MetaTags />
      <ObserveState
        listenTo={Global.getGlobal()}
        control={() => {
          if (!Global.getJwtToken()) {
            return <HasAuth />;
          } else {
            return <RequireAuth />;
          }
        }}
      />
    </HelmetProvider>
  );
}

export default App;
